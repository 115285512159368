<template>
  <div>
    <PalindromeModule />
  </div>
</template>
<script>
import PalindromeModule from '@/modules/Palindrome'

export default {
  components: {
    PalindromeModule,
  },
}
</script>
